import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';

import { OfertasComponent } from './ofertas/ofertas.component';
import { CtaCteComponent } from './cobranza/cta-cte/cta-cte.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import { EditarPerfilComponent } from './usuarios/editar-perfil/editar-perfil.component';
import { CardsArticulosComponent } from './Inventario/cards-articulos/cards-articulos.component';
import { PresupuestosComponent } from './ventas/presupuestos/presupuestos.component';
import { CardsArticulosFilteredComponent } from './Inventario/cards-articulos-filtered/cards-articulos-filtered.component';
import { CardsArticulosDetalleComponent } from './Inventario/cards-articulos-detalle/cards-articulos-detalle.component';
import { CartComponent } from './ventas/cart/cart.component';
import { VisitasComponent } from './cobranza/visitas/visitas.component';
import { ReportesComponent } from './cobranza/reportes/reportes.component';
import { DashboardVendedoresComponent } from './cobranza/dashboard-vendedores/dashboard-vendedores.component';
import { DetalleCategoryComponent } from './cobranza/dashboard-vendedores/detalle-category/detalle-category.component';
import { DetalleComponent } from './cobranza/reportes/detalle/detalle.component';
import { StepPuntoComponent } from './ventas/presupuestos/step-punto/step-punto.component';
import { PedidosComponent } from './ventas/pedidos/pedidos.component';
import { NosotrosComponent } from './empresa/nosotros/nosotros.component';
import { ContactoComponent } from './empresa/contacto/contacto.component';
import { GraciasxcomprarComponent } from './graciasxcomprar/graciasxcomprar.component';
import { RegisterComponent } from '../login/register.component';


const pagesRoutes: Routes = [
    {
        path: 'pages',
        component: PagesComponent,
        children: [
           { path: 'dashboard', component: DashboardComponent, data: { titulo: 'Dashboard' } },
           { path: 'progress', component: ProgressComponent, data: { titulo: 'Progress' } },
           { path: 'graficas1', component: Graficas1Component, data: { titulo: 'Graficas' } },
           { path: 'nosotros', component: NosotrosComponent, data: { titulo: 'Nosotros' } },
           { path: 'contacto', component: ContactoComponent, data: { titulo: 'Contacto' } },
           { path: 'register', component: RegisterComponent, data: { titulo: 'Registrarse' } },
           // ARTICULOS
           { path: 'cards-articulos', component: CardsArticulosComponent, data: { titulo: 'Catálogo de Articulos' } },
           { path: 'cards-articulos-filtered/:category/:catstr/:padre', component: CardsArticulosFilteredComponent, data: { titulo: 'Catálogo de Articulos' } },
           { path: 'cards-articulos-filtered/:text', component: CardsArticulosFilteredComponent, data: { titulo: 'Resultados de la búsqueda' } },
           { path: 'cards-articulos-detalle/:codArticulo/:flag', component: CardsArticulosDetalleComponent, data: { titulo: 'Detalle de Artículo' } },
           
           // VENTAS
           { path: 'presupuestos', component: PresupuestosComponent, data: { titulo: 'Generar Presupuesto' } },
           { path: 'step-punto', component: StepPuntoComponent, data: { titulo: 'Punto de Venta' } },
           { path: 'cart', component: CartComponent, data: { titulo: 'Carro de Compras' } },
           { path: 'pedidos', component: PedidosComponent, data: { titulo: 'Pedidos Realizados' } },
           { path: 'graciasxcomprar', component: GraciasxcomprarComponent, data: { titulo: 'Gracias!' } },
           
           // COBRANZA           
           { path: 'cta-cte', component: CtaCteComponent, data : {titulo: 'Cuenta Corriente'}},
           { path: 'visitas', component: VisitasComponent, data : {titulo: 'Visitas'}},
           { path: 'reportes', component: ReportesComponent, data : {titulo: 'Reportes'}},
           { path: 'detalle', component: DetalleComponent, data : {titulo: 'Detalle'}},
           { path: 'estadisticas', component: DashboardVendedoresComponent, data : {titulo: 'Estadísticas'}},
           { path: 'detalle-category', component: DetalleCategoryComponent, data : {titulo: 'Detalle'}},

           // OFERTAS
           { path: 'ofertas', component: OfertasComponent, data : {titulo: 'OFERTAS!!!'}},       
           // USUARIOS
           { path: 'usuarios', component: UsuariosComponent, data : {titulo: 'Crear usuario'}},
           { path: 'editar-usuario/:idUsuario', component: EditarUsuarioComponent, data : {titulo: 'Cuenta de Usuario'}},
           { path: 'editar-perfil/:idUsuario', component: EditarPerfilComponent, data : {titulo: 'Editar Perfil'}},
          
           // LOGOUT
           { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
        ]
   }
];

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
