import { Component, OnInit, Renderer2, ElementRef, Input, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import {MatRadioModule} from '@angular/material/radio';
// Servicios
import { ClientesService } from '../../../services/clientes/clientes.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { ArticulosService } from '../../../services/articulos/articulos.service';
import { VentasService } from '../../../services/ventas/ventas.service';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
import { PagosService } from '../../../services/pagos/pagos.service';
// Modelos
import { Order } from '../../../models/order';
import { Cliente } from '../../../models/clientes';
import { Usuario } from '../../../models/usuario';
import { DatePipe } from '@angular/common';
// Angular material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {MatBadgeModule} from '@angular/material/badge';


// LOADASH
import * as _ from 'lodash';
// SweetAlert
import Swal from 'sweetalert2';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';
import { Establishment } from '../../../models/establishment';

import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { APIURL } from '../../../services/apiUrl';
import { Router } from '@angular/router';
import { PresupuestosService } from '../../../services/presupuestos/presupuestos.service';
import { OrderLine } from 'src/app/models/orderLine';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  providers: [DatePipe, MatDialog]
})


    
export class CartComponent implements OnInit {

  @ViewChild('cantidad', { static: false }) inputCantidad: ElementRef;
  total: number = 0;
  subtotal: number = 0;
  // Total
  totalAux: number[] = [];
  articlesAuxuni: number[] = [];
  articlesAuxtot: number[] = [];

  order: any;
  aux: any = null;
  hideInv: boolean = false;
  ocasional: boolean = false;
  finarti: boolean = false;
  contDescuentos:number = 0;
  NombreOcasional:string;

  observationsFormGroup: FormGroup;

   // Descuentos
   discounts: any = {
    discountR: 0,
    discountC: 0,
    rechargeCC: 0,
    discountCant: 0,
    discountCards: 0
  };

  depo:boolean = false;
  durle: boolean =false;

  // mat table
  dataSource = new MatTableDataSource<any>([]);
  dataSourceOrder = new MatTableDataSource<any>([]); 
  dataSourceSearchOrder = new MatTableDataSource<any>([]);
  dataSourceFastSearch = new MatTableDataSource<any>([]);
  dataSourceSearchArticles = new MatTableDataSource<any>([]);
  displayedColumnsOrder: string[] = ['descripcion', 'cantidad', 'total', 'acciones'];
  displayedColumnsSearchOrder: string[] = ['imagen','descripcion', 'cantidad', 'total', 'acciones'];

  clients: Cliente[];
  clients1: Cliente[];
  clients2: Cliente[];
  selectedClient: any = null;
  cantArticle: number;
  flag: boolean = false;
  flagpago: boolean = true;
  flag2: boolean = false;
  flagclientes: boolean = false;
  flagarticles: boolean = false;
  establishments: Establishment[] = [];
  status: boolean = false;
  articles: any[];
  articlesSelected: any[] = [];
  selectedArticulo: any = null;
  artdataSource: any[] = [];
  token: string;
  identidad: any;
  ordercliente: number;
  establishment_id: number;
  cliente: any;
  clientedc:boolean = false;
  clientetom:boolean = false;
  presupuesto: any;
  presupuestoAux: any[] = [];
  paginator: any;
  sort: any; 
  displayedColumnsSearchArticles: string [];
  displayedColumnsFastSearch: string [];
  dataSourceArticulos: MatTableDataSource<any>;
  url:string;
  orderlineAux: any[] = [];
  clientes: any[] = [];
  seller: any;
  flagdatos: boolean = false;
  password: any = null;
  total2: number=0;
  price: any=0;
  //Datos Pedido
  namers: any = null;
  cuitdni: any = null;
  mail: any = null;
  tel: any = null;
  selectedOpcionIVA: any  = null;
  dir: any  = null;
  dirf: any =null;
  opEnvio: any=0;
  flagEnvio: any=false;
  selectedOpcionLocalidad: any = "";
  recept: any = null;
  checkedenv:boolean = false;
  flagTipo: any=false;
  //MercadoPago
  urlPref: any;

  localidades = [
    {name: 'Capital', value: 400},
    {name: 'Santa Lucia', value: 400},
    {name: 'Rivadavia',value:500},
    {name: 'Rawson',value: 500},
    {name: 'Chimbas',value: 500},
    {name: 'Pocito',value: 700},
    {name: 'Caucete',value: 1000},
    {name: 'Zonda',value: 1000},
    {name: 'Ullum',value: 1000},
    {name: '9 de Julio',value:700},
    {name: '25 de Mayo',value: 1000},
    {name: 'Albardon',value: 700},
    {name: 'Angaco',value: 1000},
    {name: 'Las Chacritas',value:  500},
  ];
 
  constructor( private _clientsService: ClientesService, private _userService: UsuarioService,
               private _articlesService: ArticulosService, private renderer: Renderer2, private router: Router,
               private _ventasService: VentasService, private datepipe: DatePipe, public dialog: MatDialog,
               private _establishmentsService: EstablishmentsService, private _presupService: PresupuestosService,
               private _vendedoresService: VendedoresService, private _pagosService: PagosService, private _formBuilder: FormBuilder) {

    this.order = new Order(null, null, null, null, [], null, null, null, null);
    this.order.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    this.order.dateTime = this.datepipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss');
    this.token = _userService.getToken();
    this.observationsFormGroup = this._formBuilder.group({
      checkedCorriente: [null],
    }); 
    this.url = APIURL.urlPublic;
    this.identidad = _userService.getIdentidad();
    if(this.identidad){
      this.namers = this.identidad.nya;
      this.mail = this.identidad.email;
      this.order.user_id = this._userService.getIdentidad().sub;
      this.establishment_id = _userService.getIdentidad().establi;}

    if (this.identidad && this.identidad.rol==='vendedor')
    {
      this._vendedoresService.getSellerUser(this.identidad.sub, this.token).subscribe(
        Response => {
          this.seller = Response.data[0];
          this._clientsService.getSellerClients(this.token, this.seller).subscribe(
            Response => {
              if (Response.status === 'success') {
                this.clientes = Response.data;
              } else {
                console.log(Response.message);
              }
            },
            error => {
              console.log('error: ', error);
          });
        },
        error => {
          console.log('error: ', error);
        });  
    }
    
    if(this.identidad){
      this._clientsService.getUserIDClients(this.identidad.sub).subscribe(
        Response => {
          if (Response.status === 'success') {
            this.cliente = Response.data[0];
            this.cuitdni = this.cliente.cuit;
            this.tel = this.cliente.tel1;
            this.dirf = this.cliente.domicilio;
          } else {
            console.log(Response.message);
          }
        },
        error => {
          console.log('error: ', error);
      });
    }
    else{
      this.cliente = 'ninguno';
    }
    
    this.displayedColumnsSearchOrder = ['imagen', 'descripcion', 'cantidad', 'total', 'acciones'];
    this.displayedColumnsSearchArticles = ['descripcion'];
    

    this._articlesService.obtenerArticulos().subscribe(
      Response => {
        if (Response.status === 'success') {
          this.articles = Response.articulos;
          this.dataSourceSearchArticles = new MatTableDataSource(this.articles);
          this.dataSourceSearchArticles.filteredData = [];
          this.flagarticles = true;
          this.calculateFinalPrices(this.articles);
          this.order.establishment_id = 1;
          this.flagclientes = true;
        } 
      },
      error => {
        console.log(error);
      });
    
    this.displayedColumnsSearchArticles.push('finalPrice');
    
    //Obtengo Datos de usuario desde DB para traer pedido 
    if(this.identidad)
    {
    this._userService.getUser(this.identidad.sub).subscribe(
      Response => {

        // Obtengo Pedido
        this._presupService.getPresupuesto(Response.user.id_presupuesto).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.order = Response.order;
              this.dataSourceSearchOrder = new MatTableDataSource(this.order);
              this.dataSourceSearchOrder.paginator = this.paginator;
              this.dataSourceSearchOrder.sort = this.sort;
              this.flag = true;
              this.flag2 = true;
              this.armarPresupuestoDisplay(this.dataSourceSearchOrder.data);
            } 
          },
          error => {
            console.log(error);
            this.flag2 = true;
          });
          //this.displayedColumnsSearchArticles.push('finalPrice');   
      
      },
      error => {
        console.log(error);
      }
      );
    }
    else if(!this.identidad && localStorage.getItem('carrito')){
      //No esta logueado pero tengo algo en localstorage
      let arrayarticuloslocal = JSON.parse(localStorage.getItem('carrito'));
      arrayarticuloslocal.orderLines = arrayarticuloslocal.orderLine;
      arrayarticuloslocal.orderLines.forEach(element => {
        element.art = element;
        console.log('asd',element.art);
      });
      this.dataSourceSearchOrder = new MatTableDataSource(arrayarticuloslocal);
      this.dataSourceSearchOrder.paginator = this.paginator;
      this.dataSourceSearchOrder.sort = this.sort;
      this.flag = true;
      this.flag2 = true;
      if(arrayarticuloslocal.orderLine.length===0)
          this.flag = false;
      this.armarPresupuestoDisplay(this.dataSourceSearchOrder.data);
    } else if(!this.identidad && !localStorage.getItem('carrito')){
        this.flag2 = true;
    }
             
   }

   armarPresupuestoDisplay(presuDB:any){
      this.presupuesto = presuDB;
      this.presupuestoAux = _.cloneDeep(this.presupuesto);
      this.presupuesto.orderLines.forEach(element => {
        this.total += element.total;
      });
      this.subtotal = this.total;
      this.total2=this.total;
   }

  applyFilter(filterValue: string) {
    if(filterValue.length > 2){
      if (filterValue !== '') {
          this.dataSourceSearchArticles.filter = filterValue.trim().toLowerCase();
          this.dataSourceSearchArticles.filterPredicate = function(data, filter: string): boolean {
            return   data.codArticulo.toString().includes(filter)
                  || data.fulldescrip.toLowerCase().includes(filter)
                  || data.ganancia.toString().includes(filter)
                  || data.category.category.toLowerCase().includes(filter)
                  || data.proveedor.descripcion.toLowerCase().includes(filter);
        };
      } else {
        this.dataSourceSearchArticles.filteredData = [];
        }
    }
  }

  test(i: number) {
    this.selectedArticulo = this.dataSourceSearchArticles.filteredData[i];
    this.orderlineAux.push(this.selectedArticulo);
    this.orderlineAux[0].unitPrice = this.selectedArticulo.costo;
    this.orderlineAux[0].id_articulo = this.selectedArticulo.codArticulo;
    this.orderlineAux[0].cantidad = 1;
    this.orderlineAux[0].total = this.selectedArticulo.costo; 
    this._presupService.addOrderLine(this.presupuesto.id, this.orderlineAux).subscribe(
    Response => {
             console.log(Response);
    },
    error =>{
      console.log(error);
      Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
    });
    this._presupService.getPresupuesto(this.presupuesto.id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.order = Response.order;
          this.dataSourceSearchOrder = new MatTableDataSource(this.order);
          this.dataSourceSearchOrder.paginator = this.paginator;
          this.dataSourceSearchOrder.sort = this.sort;
          this.presupuesto = [];
          this.presupuesto = this.dataSourceSearchOrder.data;
          this.presupuestoAux = _.cloneDeep(this.presupuesto);
          this.flag = true;
          this.total = 0;
          this.presupuesto.total=0;
          this.presupuesto.orderLines.forEach(element => {
            this.total += element.total;
          });
          this.presupuesto.total=this.total;
          this.subtotal = this.total;
          document.getElementById('totalDisplay').focus();
          this.dataSourceSearchArticles.filteredData  = [];
        } 
      },
      error => {
        console.log(error);
      });
      
  }

   ngOnInit() {

      if(this.opEnvio==2)
  {
    this.flagEnvio=false; //retiro en sucursal

  }
  else{
    this.flagEnvio=true; //retiro a domicilio
    
  }
   }

  calculateFinalPrices(articulos) {
    for (let i = 0; i < articulos.length; i++) {
      articulos[i].finalPrice = this.getFinalPrice(articulos[i]);

    }
  }

  getFinalPrice(article: any) {
    return ((article.ganancia * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
  }

   focusCantidad() {
           this.cantArticle = null;
           this.inputCantidad.nativeElement.focus();
   }

    deleteOrderLine(i: number) {  
      if(!this.identidad){
        let arrayarticuloslocal = JSON.parse(localStorage.getItem('carrito')); 
        this.total -= arrayarticuloslocal.orderLine[i].unitPrice;
        arrayarticuloslocal.orderLine.splice(i,1);
        if(arrayarticuloslocal.orderLine.length===0)
          this.flag = false;
        arrayarticuloslocal.orderLines = arrayarticuloslocal.orderLine;
        localStorage.removeItem('carrito');
        localStorage.setItem('carrito', JSON.stringify(arrayarticuloslocal));
        arrayarticuloslocal.orderLines.forEach(element => {
          element.art = element;
        });
        this.dataSourceSearchOrder = new MatTableDataSource();
        this.dataSourceSearchOrder.data = arrayarticuloslocal;
        this.dataSourceSearchOrder.paginator = this.paginator;
        this.dataSourceSearchOrder.sort = this.sort;
        this.dataSourceSearchOrder._updateChangeSubscription();
        this.subtotal = this.total;
      }    
      else{
          this.total -= this.presupuesto.orderLines[i].total;
          this.presupuesto.total -= this.presupuesto.orderLines[i].total;
          this._presupService.deleteOrderLine(this.token, this.order.id, this.presupuesto.orderLines[i]).subscribe(
            Response => {
              console.log(Response);
              this.presupuesto.orderLines.splice(i, 1);
              this.observationsFormGroup = this._formBuilder.group({
                checkedCorriente: [null]
              });
              this._presupService.getPresupuesto(this.presupuesto.id).subscribe(
                Response => {
                  if (Response.status === 'success') {
                    this.order = Response.order;
                    this.dataSourceSearchOrder = new MatTableDataSource(this.order);
                    this.dataSourceSearchOrder.paginator = this.paginator;
                    this.dataSourceSearchOrder.sort = this.sort;
                    this.presupuesto = [];
                    this.presupuesto = this.dataSourceSearchOrder.data;
                    this.presupuestoAux = _.cloneDeep(this.presupuesto);
                    this.flag = true;
                    // cerea los totales y cargarlos con foreach
                    this.total = 0;
                    this.presupuesto.total=0;
                    this.presupuesto.orderLines.forEach(element => {
                      this.total += element.total;
                    });
                    this.presupuesto.total=this.total;
                    this.subtotal=this.total;
                    if (this.order.orderLines.length === 0) {
                      this.flag = false;
                      this._userService.removePresup(this.identidad.sub).subscribe(
                        Response => {
                          console.log(Response);
                        },
                        error => {
                          console.log(error);
                        });
                    }
                    this.ngOnInit();
                  } 
                },
                error => {
                  console.log(error);
                });


            },
            error => {
              console.log(error);
            });
        } 
    }

    insertDetail(detail, id) {
      let p = this.renderer.createElement('p');
      this.renderer.setProperty(p, 'id', id);
      this.renderer.appendChild(p, detail);
      this.renderer.appendChild(document.getElementById('detalle'), p);
    }

    displayDatos(){
      this.flagdatos=true;
    }

    changecheck(){
      if(this.selectedOpcionLocalidad!=0)
      {
      this.checkedenv= !this.checkedenv;
   /*    this.checkenvio(); */
      }
      else{
        Swal.fire("Seleccione una localidad");
     /*    this.checkedenv=!this.checkedenv; */
      }

      
    }

   change()
   { 
    let auxnumber = parseFloat(this.selectedOpcionLocalidad.value); 

    let value = this.total2 + auxnumber;
    this.total = value; 
    this.presupuesto.total = value;
    console.log(this.total);
    if(localStorage.getItem('carrito')){
      let arrayarticuloslocal = JSON.parse(localStorage.getItem('carrito'));
      arrayarticuloslocal.total = value;
      localStorage.removeItem('carrito');
      localStorage.setItem('carrito', JSON.stringify(arrayarticuloslocal));
    }


   
   }
/* 
    checkenvio(){ 
      if(this.checkedenv){
        let auxnumber = parseFloat(this.selectedOpcionLocalidad);
        let value = this.total + auxnumber;
        this.total = value; 
         this.presupuesto.total = value;
        if(localStorage.getItem('carrito')){
          let arrayarticuloslocal = JSON.parse(localStorage.getItem('carrito'));
          arrayarticuloslocal.total = value;
          localStorage.removeItem('carrito');
          localStorage.setItem('carrito', JSON.stringify(arrayarticuloslocal));
        }

      } 
      else{
        this.total -= this.selectedOpcionLocalidad;
        this.presupuesto.total -= this.selectedOpcionLocalidad; 
        this.selectedOpcionLocalidad = 0;
      } 
    } */
    

    saveValue(article, i) {
          this.presupuesto.orderLines[i].cantidad = article.cantidad;
          this.total -= article.total;
          this.presupuesto.orderLines[i].total = (this.presupuesto.orderLines[i].unitPrice * article.cantidad);
          this.dataSourceSearchOrder = new MatTableDataSource(this.presupuesto);
          this.dataSourceSearchOrder.paginator = this.paginator;
          this.dataSourceSearchOrder.sort = this.sort;
          this.dataSourceSearchOrder._updateChangeSubscription();
          this.total += this.presupuesto.orderLines[i].total;
          this.subtotal = this.total;
          this.presupuesto.total = 0;
          this.presupuesto.orderLines.forEach(element => {
            this.presupuesto.total += element.total;
          });
      }

      checkvaluesMP(monto){

        this.validavalues();
        if(this.flagTipo)
        {
          if(this.identidad){
            this.flagpago = false;
            this.mercadopago(monto);
            this.generaPresupuesto();
            this._userService.removePresup(this.identidad.sub).subscribe(
              Response => {
                console.log(Response);
              },
              error => {
                console.log(error);
              });
          }
          else{
            this._userService.newUser(this.mail, this.namers, this.cuitdni, this.tel, this.password, this.dirf, null).subscribe(
              Response => {
                console.log(Response);
                this.mercadopago(monto);
                //localStorage.removeItem('carrito');
                let user = Response.user;
                let signup = Response.signup;
                let clienteaux = Response.client;
                console.log('cliente', clienteaux);
                this.cliente=clienteaux;
                console.log('clientenew', this.cliente);
                console.log(signup);
                this.identidad = user;
                this.token = signup.token;
                this.status = signup.status;
                this.generaPresupuesto();
              },
              error => {
                console.log(error);
              });
          }
        }
      }


      validavalues()
      {
        if(this.namers && this.cuitdni && this.mail && this.tel && this.dirf && (this.opEnvio==2 || this.opEnvio==1)){
        if(this.opEnvio==1)
        { if(this.dir && this.selectedOpcionLocalidad && this.recept)
          {
            this.flagTipo=true;
          }
          else
          {
            Swal.fire("Rellena todos los campos");
          }
        }
        else if(this.opEnvio==2)
        {
          this.flagTipo=true;
        }
      }
      else
      {
        Swal.fire("Rellena todos los campos");
      }
    }

      checkvalues(){
        this.validavalues();
        if(this.flagTipo)
        {
          if(this.identidad){
            this.flagpago = false;
            this.generaPresupuesto();
            this._userService.removePresup(this.identidad.sub).subscribe(
              Response => {
                console.log(Response);
              },
              error => {
                console.log(error);
              });
          }
          else{
            this._userService.newUser(this.mail, this.namers, this.cuitdni, this.tel, this.password, this.dirf, null).subscribe(
              Response => {
                console.log(Response);
                //El metodo crea un usuario si no existe en la DB caso contrario devuelve los datos del usuario
                let user = Response.user;
        
                let signup = Response.signup;
                let clienteaux = Response.client;
                console.log('cliente', clienteaux);
                this.cliente=clienteaux;
                console.log('clientenew', this.cliente);
                console.log(signup);
                this.identidad = user;
                //localStorage.setItem('identidad', JSON.stringify(this.identidad));
                this.token = signup.token;
                // localStorage.setItem('token', this.token);
                // localStorage.setItem('menu', JSON.stringify(signup.menu));
                // localStorage.setItem('establishment_id', signup.establishment_id);
                this.status = signup.status;
                this.generaPresupuesto();
              },
              error => {
                console.log(error);
              });
          }
        }
      }

      mercadopago(monto){
        console.log('monto MP:', monto);
        this._pagosService.mercadopago(monto).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.flagpago = true;
              this.urlPref=Response.urlPref;
              window.open(this.urlPref, '_blank');
              //Crear presupuesto sin pdf
              setTimeout('', 5000);
              this.router.navigate(['/pages/graciasxcomprar']);
            } else {
              console.log(Response);
            }
          },
          error => {
            console.log('error: ', error);
        });
      }


      generaPresupuesto() {
        if(localStorage.getItem('carrito')){
          let arrayarticuloslocal = JSON.parse(localStorage.getItem('carrito'));
          arrayarticuloslocal.user_id = this.cliente.user_id;   
          arrayarticuloslocal.id_cliente = this.cliente.codCliente;
          this._presupService.armarPresupuesto(arrayarticuloslocal).subscribe(
            Response => {
              console.log(Response);
              if (Response.status === 'success') {
                this.flagpago = true;
                if(this.order.establishment_id == 1)
                window.open(APIURL.urlPublic + 'pdfPresupuesto/' + Response.pdfName, '_blank');
                else
                window.open(APIURL.urlPublic + 'pdfPresupuestoTOM/' + Response.pdfName, '_blank');
                Swal.fire({
                  position: 'center',
                  type: 'success',
                  title: 'El pedido fue enviado a nuestra sucursal con éxito!np',
                  showConfirmButton: false,
                  timer: 1500
                });
                if(localStorage.getItem('carrito'))
                  localStorage.removeItem('carrito');
                let navi = this.router.navigate(['/pages/graciasxcomprar']);
              }
            },
            error =>{
              console.log(error);
              Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
            });
        }
        else{
          this.presupuesto.discount =  0;
          this.presupuesto.clientDiscount = 0;
          this.presupuesto.discountC = 0;
          this.presupuesto.discountR = 0;
          this.presupuesto.rechargeCC = 0;
          this.presupuesto.discountCant = 0;
          this.presupuesto.discountCards = 0;
          this.presupuesto.establishment_id = 1;
          this.presupuesto.pedido = 1;
          this.presupuesto.isOpen = 0;
          console.log('this.recept. this.dir', this.recept, this.dir);
       
          this.presupuesto.observacion = 'Recibe '+ this.recept +' en '+this.dir + 'Localidad: ' + this.selectedOpcionLocalidad.name;
          this.presupuesto.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
          this.presupuesto.orderLine = this.presupuesto.orderLines; 
          this.presupuesto.selectedClient = this.cliente;
          console.log(this.presupuesto);
          this._presupService.armarPresupuesto(this.presupuesto).subscribe(
            Response => {
              console.log(Response);
              if (Response.status === 'success') {
            
                if(this.order.establishment_id == 1)
                window.open(APIURL.urlPublic + 'pdfPresupuesto/' + Response.pdfName, '_blank');
                else
                window.open(APIURL.urlPublic + 'pdfPresupuestoTOM/' + Response.pdfName, '_blank');
                Swal.fire({
                  position: 'center',
                  type: 'success',
                  title: 'El pedido fue enviado a nuestra sucursal con éxito',
                  showConfirmButton: false,
                  timer: 1500
                });

                this._userService.removePresup(this.identidad.sub).subscribe(
                  Response => {
                    console.log(Response);
                  },
                  error => {
                    console.log(error);
                  });
                
                let navi = this.router.navigate(['/pages/graciasxcomprar']);
              }
            },
            error =>{
              console.log(error);
              Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
            });
          }
        }

        OnChange(event: any, option: string) {

          if (event === true) {       
            this.presupuestoAux = _.cloneDeep(this.presupuesto);
            if (option === 'CORRIENTE') {
              this.applyBoniRecar('CORRIENTE', 1);
            } 
          } else {
              if (option === 'CORRIENTE') {
                this.applyBoniRecar('CORRIENTE', -1);
                this.presupuesto.rechargeCC = 0;
              } 
          }
      }

      applyBoniRecar( option:string, r:number) {
          
          if (option === 'CORRIENTE') {
            this.total = 0;
            if(r==1){
              this.presupuesto.orderLines.forEach(element => {         
                element.unitPrice = Number(((element.unitPrice * 0.15 * r) + element.unitPrice).toFixed(2));          
                element.total = Number(((element.total * 0.15 * r) + element.total).toFixed(2));      
                this.total += element.total; 
              });
              this.presupuesto.rechargeCC = Number((this.total * 0.15 * r).toFixed(2));
              this.presupuesto.total=this.total;
              this.dataSourceSearchOrder = new MatTableDataSource(this.presupuesto);
            }
            else{
              this.presupuesto = this.presupuestoAux;
              this.presupuesto.orderLines.forEach(element => {               
                this.total += element.total; 
              });
              this.presupuesto.rechargeCC = 0;
              this.presupuesto.total = this.total;
              this.subtotal = this.total;
              this.dataSourceSearchOrder = new MatTableDataSource(this.presupuesto);
            }
          }        
        
      }

      moveToSelectedTab(tabName: string) {
        for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
        if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
           {
              (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
           }
         }
      }

      //Funciones Slider

      slideConfig = {
        "slidesToShow": 6,
        "slidesToScroll": 4,
        "infinite": false
      };

      slickInit(e:any) {
        console.log('slick initialized');
      }

      breakpoint(e:any) {
        console.log('breakpoint');
      }

      afterChange(e:any) {
        console.log('afterChange');
      }

      beforeChange(e:any) {
        console.log('beforeChange');
      }
            

       
  
}
