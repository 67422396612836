import { Injectable } from '@angular/core';

// Otros
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIURL } from '../apiUrl';

// Modelos
import { Articulo } from '../../models/articulo';

@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  url: string;
  identity;
  token;

  constructor( public _http: HttpClient) {
    this.url = APIURL.url;
  }

  crearArticulo( token, articulo: Articulo): Observable<any> {

    let json = JSON.stringify(articulo);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._http.post(this.url + 'articulos', params , {headers: headers});

  }

  obtenerArticulos(): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url + 'obtenerArticulos' , {headers: headers});
  }

  obtenerArticulosProv(prov:number, token): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.get(this.url + 'obtenerArticulosProv/' + prov , {headers: headers});
  }

  obtenerParaXLSX( token): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'obtenerParaXLSX' , {headers: headers});
  }

  obtenerPreciosXLSX( token): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'obtenerPreciosXLSX' , {headers: headers});
  }

  changeDolar(val:number, token): Observable<any> {
    
    let json = JSON.stringify(val);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.post(this.url + 'changeDolar' , params, {headers: headers});
  }

  getCotiDolar(): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url + 'getCotiDolar' , {headers: headers});
  }

  getArtWithID(id:number): Observable<any> {
    
    let json = JSON.stringify(id);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    

    return this._http.post(this.url + 'getArtWithID' , params, {headers: headers});
  }

  getArtPages(ind:number): Observable<any> {
    
    let json = JSON.stringify(ind);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
                                  

    return this._http.post(this.url + 'getArtPages' , params, {headers: headers});
  }

  obtenerArticulosSoft( token): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'obtenerArticulosSoft' , {headers: headers});
  }

  getArtForTerm(term:string = '', id:number, token): Observable<any> {
    
    let json = JSON.stringify(term);

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.post(this.url + 'getArtForTerm' , {params: json, id: id} , {headers: headers});
  }

  getArtTermCatID(term:string = '', id:number, cat:number): Observable<any> {
    
    let json = JSON.stringify(term);

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    

    return this._http.post(this.url + 'getArtTermCatID' , {params: json, id: id, cat:cat} , {headers: headers});
  }

  getArtCat( cat:any): Observable<any> {
  
    let json = JSON.stringify({ cat: cat });

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.url + 'getArtCat' , params,  {headers: headers});
  }

  getArtRel( desc:any): Observable<any> {
  
    let json = JSON.stringify({ desc: desc });

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.url + 'getArtRel' , params,  {headers: headers});
  }

  countArt( token): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'countArt' , {headers: headers});
  }

  guardarArticulos( token, articulos): Observable<any> {

    let json = JSON.stringify(articulos);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._http.post(this.url + 'guardarArticulos', params , {headers: headers});
  }

  getArticulo(codArticulo): Observable<any> {

    let json = JSON.stringify(codArticulo);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + 'getArticulo' , params , {headers: headers});
  }

  updateArticulo(token, codArticulo: number, articulo: Articulo): Observable<any> {
    let json = JSON.stringify(articulo);

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.post(this.url + 'updateArticulo' , {params: json, cod: codArticulo} , {headers: headers});
  }

  deleteArticulo(token: string, codArticulo: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.delete(this.url + 'articulos/' + codArticulo , {headers: headers});
  }

  updateArticulos(token: string, articles: any): Observable<any> {
    let json = JSON.stringify(articles);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.post(this.url + 'updateArticulos' , params , {headers: headers});
  }

  armarCatalogo( token): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'armarCatalogo' , {headers: headers});
  }

  armarCatalogoTOM( token): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'armarCatalogoTOM' , {headers: headers});
  }

}

