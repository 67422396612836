import { Component, OnInit } from '@angular/core';
// Otros
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogChangepwComponent } from './dialog-changepw/dialog-changepw';

// Modelos
import { Usuario } from '../models/usuario';

// Servicios
import { UsuarioService } from '../services/usuario/usuario.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Intercom } from 'ng-intercom';
import { Order } from 'src/app/models/order';
import { PresupuestosService } from 'src/app/services/presupuestos/presupuestos.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { Cliente } from 'src/app/models/clientes';


declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UsuarioService, MatDialog]
})
export class LoginComponent implements OnInit {

  status: string;
  usuario: Usuario;
  token: string;
  identidad: any;

  cliente: Cliente;
  order: any;

  constructor(
    public intercom: Intercom,
    public router:  Router,
    private activatedRoute: ActivatedRoute,
    private _usuarioService: UsuarioService,
    private presupuestosService: PresupuestosService,
    private clientesService: ClientesService,
    public dialog: MatDialog,
    ) {
    this.usuario = new Usuario(1, '', '', '', '', '', 1);
    this.order = new Order(null, null, null, null, [], null, null, null, null);

  }

  ngOnInit() {
    init_plugins();
  }

  ingresar() {
    
      this._usuarioService.login(this.usuario).subscribe(
       response => {

          // Obtengo token

          if (response.status === 'ok') {
            this.token = response.token;
            localStorage.setItem('token', this.token);
            localStorage.setItem('menu', JSON.stringify(response.menu));
            localStorage.setItem('establishment_id', response.establishment_id);
            this.status = response.status;

            // Obtengo Usuario
            
            this._usuarioService.login(this.usuario, true).subscribe(
              response => {

                // Obtengo Usuario
                this.identidad = response.usuario;
                localStorage.setItem('identidad', JSON.stringify(this.identidad));

                this.clientesService.getUserIDClients(this.identidad.sub).subscribe(
                  Response => {
                    if (Response.status === 'success') {
                      this.cliente = Response.data[0];
                      //console.log('clientewey1', this.cliente);
                    } else {
                      console.log(Response.message);
                    }
                  },
                  error => {
                    console.log('error: ', error);
                });

                setTimeout(() => {
                  //Busco carrito en localStorage y lo añado al usuario
                  let arrayarticuloslocal : Order;
                  if(localStorage.getItem('carrito'))
                  {
                    arrayarticuloslocal = JSON.parse(localStorage.getItem('carrito'));
                    arrayarticuloslocal.user_id = this.identidad.sub;
                    arrayarticuloslocal.id_cliente = this.cliente.codCliente;
                    //arrayarticuloslocal.selectedClient = this.cliente;
                    console.log('antes de mandarlo',arrayarticuloslocal);
                    this.presupuestosService.armarPresupuestosinPDF(arrayarticuloslocal).subscribe(
                      Response => {
                              console.log(Response);
                              if (Response.status === 'success') {
                                  this.identidad.idpresup = Response.idPres;
                                  this._usuarioService.addPresup(this.identidad.sub, this.identidad.idpresup).subscribe(
                                    Response => {
                                      console.log(Response);
                                      localStorage.removeItem('carrito');
                                    },
                                    error => {
                                      console.log(error);
                                    });
                              }
                      },
                      error =>{
                        console.log(error);
                      });

                  }
                }, 1000);
                this.intercom.boot({
                  app_id: "h609sw2a",
                  name: this.identidad.nya, // Full name
                email: this.identidad.email, // Email address   
                  // Supports all optional configuration.
                  widget: {
                    "activator": "#intercom" 
                  }
                });
                // ingreso al sistema
                this.router.navigate(['pages/cards-articulos']);
              },
              error => {
                this.status = error;
                console.log(error);

              }
            );
            } else {
              this.status = response.status;
            }

        },
        error => {
          this.status = error;
          console.log(error);

        }
      );
    //
    
  }

  modifyPass() {
    let dialog = this.dialog.open(DialogChangepwComponent, {
      disableClose: true,
      width: '35%',
      height: '28%',
      data: {
        //realAmount: 
      }
    });

     // Cuando se cierra el dialog
     dialog.afterClosed().subscribe(result => {
      if (result) {

      }
     });
  }

}
