import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order } from '../../models/order';
import { APIURL } from '../apiUrl';
import { Observable } from 'rxjs';
import { Caja } from '../../models/caja';



@Injectable({
  providedIn: 'root'
})
export class VentasService {

  url: string;

  constructor( private _httpClient: HttpClient) {
      this.url = APIURL.url;
   }

   saveOrder(token: string, order: Order): Observable<any> {

    let json = JSON.stringify(order);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'orders', params , {headers: headers, responseType: 'json'});
   }

   changePaidMethod(token: string, order: any): Observable<any> {

    let json = JSON.stringify(order);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'changePaidMethod', params , {headers: headers});
   }

   generateNoteDC(token: string, cliente: any): Observable<any> {
    //console.log('cli', cliente);
    let json = JSON.stringify(cliente);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'generateNote', params ,{headers: headers});
   }

   getOrders( token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    // .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'orders' , {headers: headers});
   }

   getOrder(token: string, idOrder: number): Observable<any> {

    let json = JSON.stringify(idOrder);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.post(this.url + 'getOrder' , params , {headers: headers});
  }

  getDevolucion(token: string, idOrder: number): Observable<any> {

    let json = JSON.stringify(idOrder);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.post(this.url + 'getDevolucion' , params , {headers: headers});
  }

  getPendientes(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'getPendientes' , {headers: headers});
  }

  cancelOrder(token: string, idOrder: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.delete(this.url + 'orders/' + idOrder , {headers: headers});
  }

  
  cancelPedido(token: string, id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'cancelPedido/' + id , {headers: headers});
  }

  deletePdfOrder(token: string, idOrder: number): Observable<any> {
    let json = JSON.stringify(idOrder);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.post(this.url + 'deletePdfOrder' , params , {headers: headers});
  }

  deletePdf(token: string, pdfUrl: string): Observable<any> {
    let json = JSON.stringify(pdfUrl);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.post(this.url + 'deletePdf' , params , {headers: headers});
  }

  consultVoucher(token: string, idVoucher: number): Observable<any> {
    let json = JSON.stringify(idVoucher);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.post(this.url + 'consultVoucher' , params , {headers: headers});
  }

  getPedidos( token: string, op:number): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    // .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'getPedidos/' + op , {headers: headers});
   }

   getPedidosCliente( token: string, op:number, cod:number): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    // .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'getPedidosCliente/' + op + '/'+ cod , {headers: headers});
   }
  

  getUnPaidInvoices(token: string, idClient: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'unPaidInvoices/' + idClient , {headers: headers});
  }

  getUnPaidInvoicesSeller(token: string, idSeller: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'unPaidInvoicesSeller/' + idSeller , {headers: headers});
  }

  getClientInvoices(token: string, codCliente: number, option: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'clientInvoices/' + codCliente + '/' + option , {headers: headers});
  }

  getComisionSeller(token: string, idSeller: number, val:number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);
    if(val==1)
      return this._httpClient.get(this.url + 'getComisionSellerAnt/' + idSeller , {headers: headers});
    else
      return this._httpClient.get(this.url + 'getComisionSeller/' + idSeller , {headers: headers});
  }

  

  getDateInvoices(token: string, from: string, to: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'dateInvoices/' + from + '/' + to , {headers: headers});
  }

  getLast4Months(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'getLast4Months' , {headers: headers});
  }

  getMejoresClientes(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'mejoresClientes' , {headers: headers});
  }
  getMejoresClientesAll(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'mejoresClientesall' , {headers: headers});
  }

  getMejoresArticulos(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'mejoresArticulos' , {headers: headers});
  }
  getMejoresArticulosAll(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'mejoresArticulosall' , {headers: headers});
  }

  getMejoresArticulos20(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'mejoresArticulos20' , {headers: headers});
  }

  getInvoice(token: string, idOrder: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'orders/' + idOrder , {headers: headers});
  }

  cobranzaInvoice(token: string, orders: any): Observable<any> {
    let jsonOrders = JSON.stringify(orders);

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.put(this.url + 'cobranzaInvoice', {params1: jsonOrders} , {headers: headers});
  }

  confirmInvoice(token: string, id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'confirmInvoice/' + id , {headers: headers});
  }

  rejectPago(token: string, id: number, amount: any): Observable<any> {

    let json = JSON.stringify(amount);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.put(this.url + 'orders/' + id, params , {headers: headers});
  }

  checkInvoice(token: string, id): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'checkInvoice/' + id, {headers: headers});
  }

  addPago(token: string, pago:Caja): Observable<any> {
    let json = JSON.stringify(pago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.post(this.url + 'addPago', params , {headers: headers});
  }

}
