import { Component, OnInit, HostListener, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { APIURL } from '../../../services/apiUrl';
import { Options, LabelType } from 'ng5-slider';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

//Services
import { ArticulosService } from '../../../services/articulos/articulos.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { CategoriesService } from '../../../services/categories/categories.service';

//Models
import { Articulo } from '../../../models/articulo';
import { Category } from '../../../models/category';

//arbol categorias
interface CategoryNode {
  id: number;
  parent_id: number;
  name: string;
  children?: CategoryNode[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  id: number;
  level: number;
}

@Component({
  selector: 'app-cards-articulos-filtered',
  templateUrl: './cards-articulos-filtered.component.html',
  styleUrls: ['./cards-articulos-filtered.component.css']
})
export class CardsArticulosFilteredComponent implements OnInit {
  token: string;
  identidad: any;
  flag: boolean;
  private _searchTerm: string;
  p: number = 1;
  cantArt:number;
  categ:number = 999;
  pdfCatalogo: string;
  cat: number;
  padre: string;
  dolar: number;
  buscartext: string;
  searchTerm:string = '';
  catestr:string = '';
  myDynamicColor:string ='orangered';
  loading:boolean = false;

  //arbol categorias
  categories: string;
  activeNode : any = null;

  private transformer = (node: CategoryNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    
  // articulos obtenidos
   articulos: any[] = [];
   filteredArticulos: any[] = [];
   filteredArticulos2: any[] = [];
   hidecat: boolean=true;

   //filtros
  minValue: number = 0;
  maxValue: number = 30000;
  options: Options = {
    floor: 0,
    ceil: 30000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min precio:</b> $' + value;
        case LabelType.High:
          return '<b>Max precio:</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };

  selectOptions = [
    {opcion: 'Más relevantes'},
    {opcion: 'Menor precio'},
    {opcion: 'Mayor precio'},
    {opcion: 'Alfabéticamente A-Z'},
    {opcion: 'Alfabéticamente Z-A'},
  ];

  selectedOpcion: any = {opcion: 'Más relevantes'};

   // Arreglo imágenes banner
  imagencat: string ='assets/images/banner/generico.jpg';
  imagesArray = [
    {name: 'Generico', categ: '9999', url: 'assets/images/banner/generico.jpg', color:'orangered'},
    {name: 'Construcción Tradicional', categ: '2' , url: 'assets/images/banner/construccion.jpg', color:'#f5de5d'},
    {name: 'Construcción en Seco', categ: '3', url: 'assets/images/banner/construccion-en-seco.jpg', color: '#f0964e'},
    {name: 'Electricidad', categ: '11', url: 'assets/images/banner/electricidad.jpg', color:'#50e6ec'},
    {name: 'Herramientas', categ: '8', url: 'assets/images/banner/herramientas.jpg', color:'#e16565'},
    {name: 'Metalúrgica', categ: '1', url: 'assets/images/banner/metalurgica.jpg', color:'#bee5c9'},
    {name: 'Ferretería', categ: '9', url: 'assets/images/banner/ferreteria.jpg', color:'#e1a1d4'},
    {name: 'Pinturería', categ: '10', url: 'assets/images/banner/pintureria.jpg', color:'#da5cc1'},
    {name: 'Pisos', categ: '5', url: 'assets/images/banner/aberturas.jpg', color:'#b9f897'},
    {name: 'Plomería', categ: '7', url: 'assets/images/banner/plomeria.jpg', color:'#9de5e9'},
    {name: 'Sanitarios', categ: '6', url: 'assets/images/banner/sanitarios.jpg', color:'#6c6ee1'},
    {name: 'Aislantes y Membranas', categ: '4', url: 'assets/images/banner/aislantes-membranas.jpg', color:'#edcee6'},
  ];

  constructor(private _activatedRoute: ActivatedRoute, private _articulosService: ArticulosService, private _usuarioService: UsuarioService, private router: Router,  private categoriesService: CategoriesService) {
    this.flag = false;
    this.token = _usuarioService.getToken();
    this.getCategories();
    this._articulosService.getCotiDolar().subscribe(
      Response => {
        if (Response.status === 'success') {
          this.dolar = Response.dolar;
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
      });
    this._activatedRoute.params.subscribe(
      params => {
        if(params['category']){
          this.cat = params['category'];
          this.padre = params['padre'];
          this.catestr = params['catstr'];
          this.imagesArray.forEach(element => {
            if(element.categ == this.padre){
              this.imagencat=element.url;
              this.myDynamicColor=element.color;
            }
          });
          this.busquedaCateg(this.cat, this.catestr);
        }
        if(params['text'])
        {
          this.buscartext = params['text'];
          this.busquedatxt(this.buscartext);
        }
      });
  }

  ngOnInit() {    
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  getCategories(){
  
    this.categoriesService.obtenerCateg().subscribe(
      Response => {
        if (Response.status === 'success') {
          this.categories = Response.children;
          this.dataSource.data = JSON.parse(this.categories);
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
      });
  }

  busquedatxt(filterValue: string) {

    this._articulosService.getArtTermCatID(filterValue, 1, 999).subscribe(
      Response => {
        this.calculateFinalPrices(Response.articulos);
        this.filteredArticulos = Response.articulos;
        this.cantArt = Response.cant;
        this.flag = true;
        this.catestr = filterValue;
      },
      error => {        
        console.log(error);
      });
    this.searchTerm = filterValue;
    this.p = 1;
    this.p +=16;
    this.categ = 999;
    this.catestr ='';
  }

  busquedaCateg(cat: number, catestr:string) {
    this.flag = false;
    this.catestr = catestr;
    this.filteredArticulos = [];
    this._articulosService.getArtTermCatID( "" , 1 , cat).subscribe(
      Response => {      
        
         this.calculateFinalPrices(Response.articulos);
         this.filteredArticulos = Response.articulos;
         this.cantArt = Response.cant;
         this.flag = true;
      },
      error => {
        console.log(error);
      });
    this.searchTerm = "";
    this.categ = cat;
    this.p = 1;
    this.p +=16;
  }

  
  getFinalPrice(ganancia, impuesto, costo, flete, costodlls=null): number {
      if(costodlls){
        costo = costodlls * this.dolar;
      }
      let x1 = parseFloat(ganancia);
      let x2 = parseFloat(costo);
      let x3 = parseFloat(flete);
      let x4 = parseFloat(impuesto);
      let cos= ((x4*x2)/100+(x3*x2)/100)+x2;   
      return (((cos*x1)/100)+cos);
}

calculateFinalPrices(articulos) {
  for (let i = 0; i < articulos.length; i++) {
    articulos[i].precioContado = this.getFinalPrice(articulos[i].ganancia, articulos[i].impuesto, articulos[i].costo, articulos[i].flete, articulos[i].costodlls);
    articulos[i].precioCtaCte = articulos[i].precioContado*1.15;
  }
}

catalogo(){
  this.loading =true;
  this._articulosService.armarCatalogoTOM(this.token).subscribe(
    Response => {      
               console.log(Response);
               if (Response.status === 'success') {
                window.open(APIURL.urlPublic + 'pdfCatalogoTOM/' + Response.pdfName, '_blank');
                this.loading =false;
               }
    },
    error =>{
      console.log(error);
      this.loading =false;
      Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
    });
}

loadMore(){
  this._articulosService.getArtTermCatID(this.searchTerm, this.p, this.categ).subscribe(
  Response => {
    this.calculateFinalPrices(Response.articulos);
    Response.articulos.forEach(element => {
      this.filteredArticulos.push(element);
    });
    this.p +=16;
  },
  error => {
    console.log(error);
  }); 
}

hideCategories(){
  this.hidecat = !this.hidecat;
}

orderFilter(){
  if(this.selectedOpcion.opcion === 'Menor precio' ){
    this.filteredArticulos.sort((a, b) => a.precioContado < b.precioContado ? -1 : a.precioContado > b.precioContado ? 1 : 0);
  }
  else if(this.selectedOpcion.opcion === 'Mayor precio' ){
    this.filteredArticulos.sort((a, b) => a.precioContado > b.precioContado ? -1 : a.precioContado < b.precioContado ? 1 : 0);
  }
  else if(this.selectedOpcion.opcion === 'Alfabéticamente A-Z' ){
    this.filteredArticulos.sort((a, b) => a.descripcion.toLowerCase() < b.descripcion.toLowerCase() ? -1 : a.descripcion.toLowerCase() > b.descripcion.toLowerCase() ? 1 : 0);
  }
  else if(this.selectedOpcion.opcion === 'Alfabéticamente Z-A' ){
    this.filteredArticulos.sort((a, b) => a.descripcion.toLowerCase() > b.descripcion.toLowerCase() ? -1 : a.descripcion.toLowerCase() < b.descripcion.toLowerCase() ? 1 : 0);
  }
}

priceFilter(){
  this._articulosService.getArtTermCatID( "" , 1 , this.categ).subscribe(
    Response => {      
        this.calculateFinalPrices(Response.articulos);
        this.filteredArticulos2 = Response.articulos;

    },
    error => {
      console.log(error);
    });
  let filter = this.filteredArticulos2.filter(filteredArticulo => filteredArticulo.precioContado > this.minValue && filteredArticulo.precioContado < this.maxValue );
  this.filteredArticulos = filter;
}

cleanAll(){
  this.flag = false;
  this.p = 1;
  this.categ = 999;
  this.catestr ='';
  this.searchTerm = "";
  
  this._articulosService.getArtWithID(1).subscribe(
    Response => {
       this.calculateFinalPrices(Response.articulos);        
       this.filteredArticulos = Response.articulos;
       this.flag = true; 
    },
    error => {
      this.flag = true;
       console.log(error);
    });
  this.router.navigate(['/pages/cards-articulos']);
}

changeRoute(node){
  this.router.navigate(['/pages/cards-articulos-filtered',node.id]);
}

}
