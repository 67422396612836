import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

//Services
import { UsuarioService } from '../../../../services/usuario/usuario.service';
import { ProveedoresService } from '../../../../services/proveedores/proveedores.service';

@Component({
  selector: 'app-detalle-category',
  templateUrl: './detalle-category.component.html',
  styleUrls: ['./detalle-category.component.css']
})
export class DetalleCategoryComponent implements OnInit {
 
 token: string;
 identidad: any;
 flag = false;
 categories: any[];
 orders: any[];

 dataSourceDetails = new MatTableDataSource<any>([]);
 displayedColumnsDetails: string[] = ['category', 'total'];

 constructor(private _usuarioService: UsuarioService, private _proveedoresService: ProveedoresService, 
  public dialog: MatDialog,  @Optional() @Inject(MAT_DIALOG_DATA) data: any) {

  this.identidad = _usuarioService.getIdentidad();
  this.token = _usuarioService.getToken();

  this._proveedoresService.detalleCategorias(this.token, data.cat, data.cli).subscribe(
    Response => {        
      if (Response.status === 'success') {       
       
        this.categories = Response.categories;
        this.orders = Response.data;
    
            for (let i = 0; i < this.orders.length; i++) {
              let flag:boolean =false;
              for (let j = 0; j < this.orders.length; j++) {
                if(this.categories[i].id == this.orders[j].id){                    
                    this.categories[i].totalgru = this.orders[j].totalgru;
                    flag =true;
                }
                
              }
              if(!flag)
                this.categories[i].totalgru = 0;
            }
          
        this.dataSourceDetails = new MatTableDataSource(this.categories);
      }
    },
    error => {
      console.log('error: ', error);
    }
  );
}

  ngOnInit() {
  }

}
