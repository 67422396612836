import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { VentasService } from '../../../services/ventas/ventas.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { Cliente } from '../../../models/clientes';
import { ClientesService } from '../../../services/clientes/clientes.service';
import { PagosService } from '../../../services/pagos/pagos.service';
import { APIURL } from '../../../services/apiUrl';
import Swal from 'sweetalert2';
import { Vendedor } from '../../../models/vendedor';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
import { Order } from '../../../models/order';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DetalleComponent } from './detalle/detalle.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css'],
  providers: [DatePipe]
})
export class ReportesComponent implements OnInit {
  
  selectedClient: any = null;
  selectedVend: any = null;
  dataSource = new MatTableDataSource<any>([]);
  dataSourceInvoices = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['state', 'nombre', 'cuit', 'domicilio', 'provincia', 'codPostal', 'tel1', 'cuentaCte', 'restoCte'];
  displayedColumnsInvoices: string[] = ['idOrder', 'fecha', 'mora', 'total','saldo','pago'];
  token: string;
  flag: boolean = false;
  cli:boolean = false;
  ven:boolean =false;
  comi:boolean =false;
  fechaForm: FormGroup;
  orders:any[];
  pagos:any[];
  vendedores: Vendedor[];
  clients: Cliente[];
  total:any = [];
  totalA:any = [];
  totalN:any = [];
  url:string;
  identidad: any;  
  Order: any;
  dataSourceDetails = new MatTableDataSource<any>([]);
  displayedColumnsDetails: string[] = ['articulo', 'cantidad', 'unitPrice', 'total'];
  
  loading:boolean = false;
  
  constructor(private _ventasService: VentasService, private _userService: UsuarioService, 
    private _clientesService: ClientesService, private _pagosService: PagosService, 
    private _vendedoresService: VendedoresService, private formBuilder: FormBuilder, private datepipe: DatePipe, public dialog: MatDialog, ) {
    this.token = _userService.getToken();
    this.url = APIURL.urlPublic;
    this.identidad = _userService.getIdentidad();
    if (this.identidad.rol === 'admin' || this.identidad.rol === 'administrativo') {
    this._clientesService.getClients(this.token).subscribe(
      Response => {          
          if (Response.status === 'success') {
              this.clients = Response.data;              
              this.flag = true;            
          } else {
            this.flag = true;
          }
      },
      error => {
        this.flag = true;
          console.log(error);
      });
      this._vendedoresService.getSellers(this.token).subscribe(
        Response => {
          //console.log(Response);
          if (Response.status === 'success') {
              let vend = new Vendedor('Sin Vendedor',23,23,'','',[],0);
              this.vendedores = Response.vendedores;
              this.vendedores.push(vend);
          } else {
            Swal.fire('Error', Response.message, 'error');
          }
        },
        error => {
          console.log('error: ', error);
        });
      } else if (this.identidad.rol === 'vendedor') {

        this._vendedoresService.getSellerUser(this.identidad.sub, this.token).subscribe(
          Response => {
            this.selectedVend = Response.data[0];            
          },
          error => {
            console.log('error: ', error);
          });   
          this._ventasService.getUnPaidInvoicesSeller(this.token, this.identidad.sub).subscribe(
              Response => {
                if (Response.status === 'success') {
                  this.clients = Response.clientes;
                    this.orders = Response.orders;
                    this.totalN = Response.total;
                    this.totalA = Response.totalA;
                    this.total = this.totalN;                 
                    // console.log('tota',Response.total);                   
                  this.ven = true;
                  this.flag = true;
                }
              },
              error => {
                console.log(error);
              });
      }
   }

  ngOnInit() {
    let today = new Date();
    let toda = new Date();
    let fechainicio;    
      fechainicio = new Date(2016,1);
    let fechafin = new Date(today.setDate(today.getDate() + 1));
    this.fechaForm = this.formBuilder.group ({
      selectedFrom : [fechainicio, Validators.required],
      selectedTo : [fechafin, Validators.required]
    });
  }

  porcliente(){
    this.cli = true;
    this.ven = false;
  }
  
  porvendedor(){
    this.cli = false;
    this.ven = true;    
  }

  cometaCont(idOrder:number){
    const dialogRef = this.dialog.open(DetalleComponent, {
      width: 'auto',
      data: {
          id: idOrder,
          pago: 'Cont'
      }
    });
  }

  cometaCC(idOrder:number){
    const dialogRef = this.dialog.open(DetalleComponent, {
      width: 'auto',
      data: {
          id: idOrder,
          pago: 'CC'
      }
    });
  }

  generarPDF(){
      
    this._pagosService.generaReporte(this.token,this.selectedClient.codCliente).subscribe(
      Response=>{
        if (Response.status === 'success') {
          window.open(APIURL.urlPublic + 'reportes/' + Response.pdfName, '_blank');
        }
        else
        Swal.fire('Atencion!','El cliente no tiene facturas pendientes de pago','info');
      },
      error => {
        console.log(error);
      });
  }

  generarPDFVend(){
    if (this.fechaForm.controls['selectedFrom'] && this.fechaForm.controls['selectedTo']) {
      let fromd = new Date(this.fechaForm.controls['selectedFrom'].value);   
      let from:string = this.datepipe.transform(fromd, 'yyyy-MM-dd hh:mm:ss');      
      let tod = new Date(this.fechaForm.controls['selectedTo'].value);      
      let to:string = this.datepipe.transform(tod, 'yyyy-MM-dd hh:mm:ss');
    this._pagosService.generaReporteXVend(this.token,this.selectedVend.id,from,to).subscribe(
      Response=>{
        if (Response.status === 'success') {
          window.open(APIURL.urlPublic + 'reportesVend/' + Response.pdfName, '_blank');
        }
        else
        Swal.fire('Atencion!','El cliente no tiene facturas pendientes de pago','info');
      },
      error => {
        console.log(error);
      });
    } else {
      Swal.fire('Cuidado!', 'No se ha seleccionado la fecha', 'warning');
    }
  }
  get f() { return this.fechaForm.controls; }

  generarPDFResumenCli(){
    if (this.fechaForm.controls['selectedFrom'] && this.fechaForm.controls['selectedTo']) {
      let fromd = new Date(this.fechaForm.controls['selectedFrom'].value);   
      let from:string = this.datepipe.transform(fromd, 'yyyy-MM-dd hh:mm:ss');      
      let tod = new Date(this.fechaForm.controls['selectedTo'].value);      
      let to:string = this.datepipe.transform(tod, 'yyyy-MM-dd hh:mm:ss');
     
    this._pagosService.generaReporteXResumenCli(this.token, from, to).subscribe(
      Response=>{
        if (Response.status === 'success') {
          window.open(APIURL.urlPublic + 'reportesVend/' + Response.pdfName, '_blank');
        }
        else
        Swal.fire('Atencion!','El cliente no tiene facturas pendientes de pago','info');
      },
      error => {
        console.log(error);
      });
    } else {
      Swal.fire('Cuidado!', 'No se ha seleccionado la fecha', 'warning');
    }
  }

  getDiffDays(fechaCompra: any): any {
    fechaCompra = new Date(fechaCompra).getTime();
    let today = new Date().getTime();
    let diff = today - fechaCompra;

    return (diff / (1000 * 60 * 60 * 24)).toFixed(0);
    // return today.diff()
  }

  calcularFinal(order:any):number{
    let val:number = 0;
    if(order){
    order.forEach(element => {
      val += element.saldo;
    });    
    return val;
    }
    else return 0;
  }

  calcularFinalComi(order:any,pago:any):number{
    let val:number = 0;
    if(order){
    order.forEach(element => {
      if(order.pago != 'Cuenta corriente')
        val += element.total;
    });
    }
    if(pago){
      pago.forEach(element => {
        val += element.total;
      });
    }
    return val;
  }

  cargarDatos() {
    let arrayAux = [];
    arrayAux.push(this.selectedClient);
    this.dataSource = new MatTableDataSource(arrayAux);
    // Traer facturas inpagas del cliente.
    this._ventasService.getUnPaidInvoices(this.token, this.selectedClient.codCliente).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.dataSourceInvoices = new MatTableDataSource(Response.orders);
        }
      },
      error => {
        console.log(error);
      });
  }

  cargarDatosVen() {    
    // Traer facturas inpagas del cliente. 
    if(this.selectedVend.id != 13){
    this.comi = false;   
    this.loading = true;
    this._ventasService.getUnPaidInvoicesSeller(this.token, this.selectedVend.id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.clients = Response.clientes;
         
          this.orders = Response.orders;          
          console.log('oddd', this.orders);
          this.totalN = Response.total;
          this.totalA = Response.totalA;
          this.total = this.totalN;                 
          console.log('tota',Response.total,Response.totalA);
          this.loading = false;
        }
      },
      error => {
        console.log(error);
      });
    }
    else{
      this._ventasService.getUnPaidInvoicesSeller(this.token, this.selectedVend.id).subscribe(
        Response => {
          if (Response.status === 'success') {              
            console.log('oddd', this.orders);
            this.totalN = Response.total;
            this.totalA = Response.totalA;
            this.total = this.totalN;                 
            console.log('tota',Response.total,Response.totalA);
            this.mostrarComision(0);
          }
        },
        error => {
          console.log(error);
        });     
    }
  }

  mostrarComision(val:number){
    this.comi = true;
    this.loading = true;
    this._ventasService.getComisionSeller(this.token, this.selectedVend.id,val).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.clients = Response.clientes;
          this.orders = Response.orders;
          this.pagos = Response.pagos;
          // console.log('or', this.orders);
          // console.log('pa', this.pagos);
          this.loading = false;
        }
      },
      error => {
        console.log(error);
      });

  }

  setdates(val){
    if(val==1){
      this.total = this.totalA;
      this.mostrarComision(1);
    }
    else
      this.total = this.totalN;
  }
}
