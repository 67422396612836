import { Injectable } from '@angular/core';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentsService {
  url: string;
  constructor(private _httpClient: HttpClient) {
    this.url = APIURL.url;
   }

  getEstablishments(): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');

    return this._httpClient.get(this.url + 'establishments' , {headers: headers});
}

}
