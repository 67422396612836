import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Cliente } from '../../../models/clientes';
import { ThousandsPipe } from '../../../pipes/thousands.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../../components/snackbar/snackbar.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { APIURL } from '../../../services/apiUrl';
import Swal from 'sweetalert2';

// Services
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { VentasService } from '../../../services/ventas/ventas.service';
import { PagosService } from '../../../services/pagos/pagos.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';

//Models
import { Usuario } from 'src/app/models/usuario';
import { Vendedor } from '../../../models/vendedor';
import { Caja } from 'src/app/models/caja';

@Component({
  selector: 'app-cta-cte',
  templateUrl: './cta-cte.component.html',
  styleUrls: ['./cta-cte.component.css']
})
export class CtaCteComponent implements OnInit {
  
  token: string;
  identidad: any;
  seller: Vendedor;
  dataSourceDevolucion = new MatTableDataSource<any>([]);
  displayedColumnsDevolucion: string[] = ['articulo', 'devueltos', 'unitPrice', 'total'];
  dataSourceDetails = new MatTableDataSource<any>([]);
  displayedColumnsDetails: string[] = ['articulo', 'cantidad', 'unitPrice', 'total'];
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['factura', 'idOrder', 'fecha', 'total', 'totalSaldo', 'mora', 'payments'];
  Order: any;
  dataSourcePagos = new MatTableDataSource<any>([]);
  displayedColumnsPagos: string[] = [
    'id',
    'amount',
    'date',    
    //'user_id',
    'state'
  ];
  // ESTADO GENERAL DEL CLIENTE
  adeuda: number = 0;
  adeudastr: string;
  entregado: number = 0;
  entregadostr: string;
  saldo: number = 0;
  saldostr: string;
  // CHECKBOXES
  checkAdeudadas: boolean = true;
  checkCanceladas: boolean;
  checkTodas: boolean;
  checkPrecanceladas: boolean;
  // CHECKBOX MAT TABLE
  selection = new SelectionModel<any>(true, []);
  devolucion:any;
  // MAT TABLE VISIBILITY
  matTableFlag: boolean = true;
  soliCC: boolean = true;  
    // BUSCAR FACTURA
    searchingInvoice: number = null;
    pagoForm: FormGroup;
    pagoInput: boolean = false;
    submitted: boolean = false;
  // SELECT
  clients: Cliente[] = [];
  uncliente: Cliente;
  selectedClient = null;
  flag: boolean = false;
  panelOpenState :boolean = false;
  user: Usuario;
  durationInSeconds = 8;
  ventas:any [] = [];


  constructor(private _usuarioService: UsuarioService, 
              private _ventasService: VentasService,
              private _clientesService: ClientesService, 
              private _vendededoresService: VendedoresService,
              private _snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _pagosService: PagosService) {

    this.identidad = _usuarioService.getIdentidad();
    this.token = _usuarioService.getToken();
    if(this.identidad.cliente == 'Quiero Aumento de CC')
      this.soliCC = false;

      this._vendededoresService.getSellerUser(this.identidad.sub, this.token).subscribe(
        Response => {
          this.seller = Response.data[0];
          this.getClients();
        },
        error => {
          console.log('error: ', error);
        });
  }

  ngOnInit() {
    this.openSnackBar();
  }

  getClients() {
    if (this.identidad.rol === 'admin' || this.identidad.rol === 'administrativo') {
      this._clientesService.getClients(this.token).subscribe(
        Response => {
          if (Response.status === 'success') {
            // Asigno datos selected
            this.clients = Response.data;
            this.flag = true;
          }
        },
        error => {
          console.log('error: ', error);
        });
    } else if (this.identidad.rol === 'clientedc' || this.identidad.rol === 'clientetom') {
      this._clientesService.getUserIDClients(this.identidad.sub).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.uncliente = Response.data[0];
              this.getInvoices();
              this.flag = true;
            }
          },
          error => {
            console.log('error: ', error);
          });
    }
    else if (this.identidad.rol === 'vendedor') {
      //this.seller.user_id
      if(this.seller.user_id==6)
      {
        this._clientesService.getSellerClients(this.token, 6).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.clients = Response.data;
              this.flag = true;             
            }
          },
          error => {
            console.log('error: ', error);
          });
      }else{
        this._clientesService.getClients(this.token).subscribe(
          Response => {
            if (Response.status === 'success') {
              // Asigno datos selected
              this.clients = Response.data;
              this.flag = true;
            }
          },
          error => {
            console.log('error: ', error);
          });
      }
    }
    
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: this.durationInSeconds * 1000
    });
  }

  loadPagos(id:number) {
    this.dataSourceDevolucion = null;
    this.devolucion = null;
    this.dataSourceDetails = null;
    this.dataSourcePagos = null;
    this._pagosService.getPagos(this.token, id).subscribe(
      Response => {
        if (Response.status === 'success') {
          //this.loadData = false;
          this.dataSourcePagos = new MatTableDataSource(Response.data);
        }
      },
      error => {
        console.log('error: ', error);
      }
    );
    this.loadDetalle(id);
    this.loadDevolucion(id);
  }

  loadDetalle(id:number){
    this._ventasService.getOrder(this.token, id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.Order = Response.order;          
          this.dataSourceDetails = new MatTableDataSource(this.Order.orderLines);          
        } else {
          Swal.fire('Error', Response.message, 'error');
        }

      },
      error => {
        console.log(error);
        Swal.fire('Error', 'Algo salio mal!', 'error');
      });
  }

  loadDevolucion(id:number){
    this._ventasService.getDevolucion(this.token, id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.devolucion = Response.order.orderLine;
          this.dataSourceDevolucion = new MatTableDataSource(Response.order.orderLine);        
        } else {
          Swal.fire('Error', Response.message, 'error');
        }

      },
      error => {
        console.log(error);
        Swal.fire('Error', 'Algo salio mal!', 'error');
      });
  }

  getInvoices() {
    if(this.identidad.rol === 'admin' || this.identidad.rol === 'administrativo' || this.identidad.rol === 'vendedor'){
    if (this.selectedClient) {
      this.selection.clear();
    let option = null;
    if (this.checkAdeudadas) {
        option = 1;
    } else if (this.checkCanceladas) {
        option = 2;
    } else if (this.checkPrecanceladas) {
        option = 3;
    }

    this._ventasService.getClientInvoices(this.token, this.selectedClient.codCliente, option).subscribe(
      Response => {
        if (Response.status === 'success') {
          if (Response.data.length > 0) {
              this.matTableFlag = false;
          }
          this.ventas = Response.data;
          this.dataSource = new MatTableDataSource(Response.data);
          this.initDesc();
        }
      },
      error => {
        console.log('error Invoices client', error);
      });
    } else {
      Swal.fire('Cuidado!', 'No se ha seleccionado ningun cliente', 'warning');
    }
  }
    else{
    if(this.uncliente){
      let option = null;
    if (this.checkAdeudadas) {
        option = 1;
    } else if (this.checkCanceladas) {
        option = 2;
    } else if (this.checkPrecanceladas) {
        option = 3;
    }

    this._ventasService.getClientInvoices(this.token, this.uncliente.codCliente, option).subscribe(
      Response => {
        if (Response.status === 'success') {
          if (Response.data.length > 0) {
              this.matTableFlag = false;
          }
          this.ventas = Response.data;
          this.dataSource = new MatTableDataSource(Response.data);
          this.initDesc();
        }
      },
      error => {
        console.log('error Invoices client', error);
      });
      }else{console.log('error en uncliente element');}
    }
    this.openSnackBar();
  }

  getInvoice() {

    if (this.searchingInvoice) {
      this.selection.clear();
        this._ventasService.getInvoice(this.token, this.searchingInvoice).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.clearDataTable();
              let aux = [];
              aux.push(Response.data);
              if (aux.length > 0) {
                this.matTableFlag = false;
              }
              this.dataSource = new MatTableDataSource(aux);
              this.initDesc();
            } else {
              Swal.fire('Error', Response.message, 'error');
            }
          },
          error => {
            console.log('error get invoice: ', error);
          });
    }
  }
  getDiffDays(fechaCompra: any) {
    fechaCompra = new Date(fechaCompra).getTime();
    let today = new Date().getTime();
    let diff = today - fechaCompra;

    return (diff / (1000 * 60 * 60 * 24)).toFixed(0);
    // return today.diff()
  }


  initDesc() {
    this.dataSource.filteredData.forEach(row => this.selection.select(row));
    this.calculaEstadoGeneral();
  }

  calculaEstadoGeneral() {
    this.adeuda = 0;
    this.saldo = 0;
    this.entregado = 0;
    let auxAdeuda: string;
    let auxSaldo: string;
    let auxEntregado: string;

    for (let i = 0; i < this.selection.selected.length; i++) {
      auxAdeuda = (this.selection.selected[i].total).toFixed(2);
      this.adeuda += Number(auxAdeuda);
      // this.adeuda += this.selection.selected[i].total;
      if(this.selection.selected[i].pago =='Cuenta corriente'){
        auxSaldo = this.selection.selected[i].saldo.toFixed(2);
        this.saldo += Number(auxSaldo);
      }     
      // this.saldo += this.selection.selected[i].saldo;
       auxEntregado = Number(this.selection.selected[i].total - this.selection.selected[i].saldo).toFixed(2);
      // this.entregado += this.selection.selected[i].total - this.selection.selected[i].saldo;
      this.entregado += Number(auxEntregado);
    }
    this.adeudastr = new ThousandsPipe().transform(this.adeuda.toFixed(2));
    this.saldostr = new ThousandsPipe().transform(this.saldo.toFixed(2));
    this.entregadostr = new ThousandsPipe().transform(this.entregado.toFixed(2));
  }

  clearDataTable() {
    this.selection.clear();
    this.dataSource.data = [];
    this.calculaEstadoGeneral();
  }

  solicitaAumento(){
    
    this._usuarioService.solicitaCC(this.identidad.sub, this.token).subscribe(
      Response => {
        if (Response.status === 'success') {
          Swal.fire('Éxito','Su solicitud sera revisada por nuestro equipo y te enviaremos la respuesta','success');
          this.soliCC = false;
        } else {
          Swal.fire('Error', Response.message, 'error');
        }
      },
      error => {
        console.log('error get invoice: ', error);
      });
  }

  statusCheckAdeudadas() {
    if (this.checkAdeudadas) {
        this.checkCanceladas = false;
        this.checkTodas = false;
        this.checkPrecanceladas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  statusCheckCanceladas() {
    if (this.checkCanceladas) {
      this.checkAdeudadas = false;
      this.checkTodas = false;
      this.checkPrecanceladas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  statusCheckTodas() {
    if (this.checkTodas) {
      this.checkAdeudadas = false;
      this.checkCanceladas = false;
      this.checkPrecanceladas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  statusCheckPrecanceladas() {
    if (this.checkPrecanceladas) {
      this.checkAdeudadas = false;
      this.checkCanceladas = false;
      this.checkTodas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  addPago(){
    this.pagoInput = !this.pagoInput;
    this.pagoForm = this.formBuilder.group ({
      observacion : ['', Validators.required],
      total : ['', Validators.required],
      cheque1 : [''],
      cheque2 : [''],
      cheque3 : [''],
      cheque4 : [''],
      cheque5 : [''],
      mcheque1 : [''],
      mcheque2 : [''],
      mcheque3 : [''],
      mcheque4 : [''],
      mcheque5 : ['']
    });
  }

  addPagoBD(){
    if(this.pagoForm.controls['cheque1'].value != '' && this.pagoForm.controls['cheque1'].value != undefined){
      this.pagoForm.controls['mcheque1'].value
    }
    let pago= new Caja(this.identidad.sub, this.pagoForm.controls['observacion'].value,this.pagoForm.controls['total'].value, 
  this.selectedClient.codCliente,this.pagoForm.controls['cheque1'].value,this.pagoForm.controls['cheque2'].value,this.pagoForm.controls['cheque3'].value,
   this.pagoForm.controls['cheque4'].value,this.pagoForm.controls['cheque5'].value,this.pagoForm.controls['mcheque1'].value,this.pagoForm.controls['mcheque2'].value,this.pagoForm.controls['mcheque3'].value,
   this.pagoForm.controls['mcheque4'].value,this.pagoForm.controls['mcheque5'].value);
    
    this._ventasService.addPago(this.token,pago).subscribe(
      Response => {
        
        if (Response.status === 'success') {
          window.open(APIURL.urlPublic + 'caja/' + Response.pdfName, '_blank');
          Swal.fire('Exito', Response.message, 'success');
        } else {
          Swal.fire('Error', Response.message, 'error');
        }
      },
      error => {
        console.log('error get invoice: ', error);
      });

      this.pagoForm = this.formBuilder.group ({
        observacion : ['', Validators.required],
        total : ['', Validators.required],
        cheque1 : [''],
        cheque2 : [''],
        cheque3 : [''],
        cheque4 : [''],
        cheque5 : [''],
        mcheque1 : [''],
        mcheque2 : [''],
        mcheque3 : [''],
        mcheque4 : [''],
        mcheque5 : ['']
      });
    this.pagoInput = !this.pagoInput;
  }
  get d() { return this.pagoForm.controls; }
}
