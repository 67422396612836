import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APIURL } from '../apiUrl';
import { Usuario } from '../../models/usuario';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  apiUrl: string;
  identidad: any;
  token: string;
  menu: any[] = [];

  constructor(
    public _http: HttpClient
  ) {
   this.apiUrl = APIURL.url;
  }

  login( usuario: Usuario, gettoken = null): Observable<any> {

    if ( gettoken === true) {
      usuario.gettoken = 'true';
    }

    let json = JSON.stringify(usuario);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
                                  //  .set('Access-Control-Allow-Origin', '*');

    return this._http.post(this.apiUrl + 'login', params , {headers: headers});
  }

  singUp(usuario: Usuario, gettoken): Observable<any> {
    let json = JSON.stringify(usuario);
    let params = 'json=' + json;

    if ( gettoken === true) {
      usuario.gettoken = 'true';
    }

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this.apiUrl + 'registro', params , {headers: headers});

  }

  updateUser(idUser: number, usuario: Usuario, token): any{
    let json = JSON.stringify(usuario);
    
    let headers = new HttpHeaders().set('Authorization', token)
                                   .set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.post(this.apiUrl + 'updateUsers/' , {params: json, id: idUser}, {headers: headers});
  }

  solicitaCC(idUser: number, token): any{  

    let headers = new HttpHeaders().set('Authorization', token)
                                   .set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.get(this.apiUrl + 'updateCC/' + idUser , {headers: headers});
  }

  changePass(mail: string, pass: string, token): Observable<any> {

    let json = JSON.stringify({ email: mail,
                                password: pass,});

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Authorization', token)
                                   .set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'changePass' , params,  {headers: headers});
  }

  addPresup(idUser: number, idPres: number): Observable<any> {

    let json = JSON.stringify({ id: idUser,
                                pres: idPres});

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'addPres' , params,  {headers: headers});
  }

  removePresup(idUser: number): Observable<any> {

    let json = JSON.stringify({ id: idUser});

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'removePres' , params,  {headers: headers});
  }

  getUser(idUser: number): Observable<any> {

    let json = JSON.stringify({ id: idUser});

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'getUser' , params,  {headers: headers});
  }

  changeRecoverPass(mail: string, pass: string, token: string): Observable<any> {

    let json = JSON.stringify({ email: mail,
                                password: pass,
                                resetToken: token});

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'changeRecoverPass' , params,  {headers: headers});
  }

  recoverPass(mail: string): Observable<any> {

    let json = JSON.stringify({ email: mail });

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'recoverPass' , params,  {headers: headers});
  }

  newUser(email: string, nya: any, cuit: any, tel1: any, password:any, direccion:any, localidad:any): Observable<any> {

    let json = JSON.stringify({ email: email,
                                nya: nya,
                                cuit: cuit,
                                tel1: tel1,
                                password: password,
                                direccion: direccion,
                                localidad: localidad
                              });

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'newusermail' , params,  {headers: headers});
  }

  contacto(email: string, nombre: any, mensaje:any): Observable<any> {

    let json = JSON.stringify({ email: email,
                                nombre: nombre,
                                mensaje: mensaje});

    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    
    return this._http.post(this.apiUrl + 'contacto' , params,  {headers: headers});
  }

  getIdentidad() {
    let identidad = JSON.parse(localStorage.getItem('identidad'));

    if (identidad !== 'undefined') {
      this.identidad = identidad;
    } else {
      this.identidad = null;
    }

    return this.identidad;
  }

  getMenu() {
    let menu = JSON.parse(localStorage.getItem('menu'));

    if (menu !== 'undefined') {
      this.menu = menu;
    } else {
      this.menu = null;
    }

    return this.menu;
  }

  /*getEstablishment(): number {
    let establishment_id = parseInt(localStorage.getItem('establishment_id'), 10);
    if (establishment_id) {
      return  establishment_id;
    } else {
      return null;
    }
  }*/

  getToken() {
    let token = localStorage.getItem('token');

    if (token !== 'undefined') {
      this.token = token;
    } else {
      this.token = null;
    }

    return this.token;
  }

}
