import { Injectable } from '@angular/core';

// Otros
import { Observable } from 'rxjs';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Modelo
import { Cliente } from '../../models/clientes';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  url: string;
  identity;
  token;

  constructor( public _http: HttpClient) {
    this.url = APIURL.url;
  }


  cargarCliente(token: string, cliente: Cliente): Observable<any> {

    let json = JSON.stringify(cliente);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

  return this._http.post(this.url + 'clientes', params , {headers: headers});
  }

  getClients(token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClients' , {headers: headers});
  }

  getLastClient(token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getLastClient' , {headers: headers});
  }

  getClientsDepo(token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClientsDepo' , {headers: headers});
  }

  getClientsDurle(token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClientsDurle' , {headers: headers});
  }

  getSellerClients(token: string, id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClients/' + id , {headers: headers});
  }

  getUserIDClients(id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.get(this.url + 'getClient/' + id , {headers: headers});
  }

  getClient(token: string, codClient): Observable<any> {
      let json = JSON.stringify(codClient);
      let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

      return this._http.post(this.url + 'getClient' , params , {headers: headers});
  }

  getClientUserID(token: string, userid): Observable<any> {
    let json = JSON.stringify(userid);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.post(this.url + 'getClientUserID' , params , {headers: headers});
}

  getClientName(token: string, name): Observable<any> {
    let json = JSON.stringify(name);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.post(this.url + 'getClientName' , params , {headers: headers});
}

  updateClient(token: string, codClient: number, cliente: Cliente): Observable<any> {
    let json = JSON.stringify(cliente);

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.post(this.url + 'updateClient' , {params: json, cod: codClient} , {headers: headers});
  }

  deleteClient(token: string, codClient: number): Observable<any> {
      let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

      return this._http.delete(this.url + 'clientes/' + codClient , {headers: headers});
  }

  getClientsForVisits(token: string, idSeller: number){
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClientsForVisits/' + idSeller , {headers: headers});
  }

  getClientsForVisits1(token: string, idSeller: number){
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClientsForVisits1/' + idSeller , {headers: headers});
  }

  getClientsForVisits2(token: string, idSeller: number){
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                    // .set('Access-Control-Allow-Origin', '*')
                                    .set('Authorization', token);

    return this._http.get(this.url + 'getClientsForVisits2/' + idSeller , {headers: headers});
  }
}

