import { Routes, RouterModule } from '@angular/router';

// Componentes
import { CardsArticulosComponent} from './pages/Inventario/cards-articulos/cards-articulos.component';
import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { RegisterComponent } from './login/register.component';
import { ChangepwComponent } from './login/changepw.component';


const appRoutes: Routes = [
    { path: '', redirectTo: 'pages/cards-articulos', pathMatch: 'full' },
    { path: 'cards-articulos', component: CardsArticulosComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'changepw', component: ChangepwComponent },
    { path: '**', component: NopagefoundComponent },
];

export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
