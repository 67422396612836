import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientesService } from '../services/clientes/clientes.service';
import { UsuarioService } from '../services/usuario/usuario.service';
import { APIURL } from '../services/apiUrl';
import Swal from 'sweetalert2';
import * as EmailValidator from 'email-validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit {
  //Datos Pedido
  namers: any = null;
  cuitdni: any = null;
  mail: any = null;
  tel: any = null;
  password: any=null;
  password2: any=null;
 /*  selectedOpcionIVA: any  = null; */
  dir: any  = null;
  identidad: any;
  token: string;
  selectedOpcionLocalidad: any = 0;
  recept: any = null;
  checkedenv:boolean = false;
  status: boolean = false;
  cliente: any;
  url:string;
  flag=false;
  constructor( private _userService: UsuarioService,  private router: Router) { 
  
    this.url = APIURL.urlPublic;
    this.identidad = _userService.getIdentidad();
  }

  ngOnInit() {
  }

  
  moveToSelectedTab(tabName: string) {
    for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
       {
          (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
       }
     }
  }

  registrar(){
  this._userService.newUser(this.mail, this.namers, this.cuitdni, this.tel, this.password, this.dir, this.selectedOpcionLocalidad).subscribe(
    Response => {
      //El metodo crea un usuario si no existe en la DB caso contrario devuelve los datos del usuario
      let user = Response.user;
      Swal.fire(Response.message);
        let signup = Response.signup;
        let clienteaux = Response.client;
        this.cliente=clienteaux;
        this.identidad = user;
        this.token = signup.token;
      setTimeout('', 5000);
        this.router.navigate(['/login']);
    },
    
    error => {
      console.log(error);
    });
  }

  
valida()
{
  if( EmailValidator.validate(this.mail)==false)
 {
   Swal.fire("Ingrese un correo electrónico válido");

 }
 else if(this.password == ""|| this.password2 =="" || this.namers=="" || this.dir=="" || this.selectedOpcionLocalidad=="0" ||this.mail=="" )
 {
   
   Swal.fire("Rellene todos los campos");
 }else if (this.password!=this.password2 || this.password2!=this.password)
  {
  Swal.fire("Las contraseñas no coinciden");
  } else 
  {

    this.registrar();
  }
 
  

}
}

