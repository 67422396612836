import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Componentes
import { HeaderComponent } from './header/header.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { BackComponent } from '../components/back/back.component';
import {MatExpansionModule} from '@angular/material/expansion';

// Ruta
import { RouterModule } from '@angular/router';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
// Servicios




@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        NgProgressModule,
        NgProgressHttpModule,
        MatExpansionModule,
    ],
    declarations: [
        HeaderComponent,
        BreadcrumbsComponent,
        NopagefoundComponent,
        BackComponent
    ],
    exports: [
        HeaderComponent,
        BreadcrumbsComponent,
        NopagefoundComponent,
        MatExpansionModule,
    ]
})

export class SharedModule { }
