import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Router} from '@angular/router';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';

//Services
import { UsuarioService } from 'src/app/services/services.index';
import { ArticulosService } from 'src/app/services/services.index';
import { PresupuestosService } from 'src/app/services/presupuestos/presupuestos.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';

//Models
import { Order } from 'src/app/models/order';
import { Cliente } from 'src/app/models/clientes';


@Component ({
  selector: 'graciasxcomprar',
  templateUrl: './graciasxcomprar.component.html',
  providers: [DatePipe]
})

export class GraciasxcomprarComponent implements OnInit {

  // Datos usuario
  token: string;
  identity: any;
  establishment_id: number;
  cliente: Cliente;
  flagpresupuesto: any;

  // Bandera
  dataReady: boolean = false;
  dataSourceSearchOrder: any;
  paginator: any;
  sort: any;
  auxOrder: any;

  constructor(private activatedRoute: ActivatedRoute, private _usuarioService: UsuarioService,
              private articuloService: ArticulosService, private router: Router, 
              private presupuestosService: PresupuestosService, private datepipe: DatePipe,
              private clientesService: ClientesService,) {

  }

  ngOnInit() {
  }

  OnSubmit() {
  }



 


}
